import config from "@shared/config/client";
import { cache } from "react";
import { fetchApi } from "..";
import { ICallableRequestBuilder } from "./types";

export abstract class RequestBuilder<T> implements ICallableRequestBuilder<T> {
  constructor(
    arg: string | ((requestInit: RequestInit) => Promise<T>) | undefined,
  ) {
    if (typeof arg === "function") {
      this.callback = cache(arg);
    } else if (typeof arg === "string") {
      this.url = arg;
    }
    this.requestInit = {};
  }

  public url?: string;
  public requestInit: RequestInit;
  public callback?: (requestInit: RequestInit) => Promise<T>;

  abstract getBlockchain(): Promise<string | undefined>;

  public async chainify() {
    const chainCookie = await this.getBlockchain();
    const blockchain = chainCookie || config.defaultChain;

    this.requestInit = {
      ...this.requestInit,
      headers: {
        ...this.requestInit?.headers,
        "x-chain": blockchain,
      },
    };

    return this;
  }

  public async call(
    url?: string,
    updateRequestInit?: (init: RequestInit) => RequestInit,
  ) {
    const updatedRequestInit = updateRequestInit
      ? updateRequestInit(this.requestInit)
      : this.requestInit;

    const calcualtedUrl = url ? url : this.url;
    if (this.callback) {
      return await this.callback(updatedRequestInit);
    } else if (calcualtedUrl) {
      const response = await fetchApi(calcualtedUrl, updatedRequestInit);

      const contentType = response.headers.get("Content-Type");

      if (contentType?.includes("application/json")) {
        return (await response.json()) as T;
      }

      return (await response.text()) as T;
    } else {
      throw Error("Wrong ServerRequestBuilder call");
    }
  }
}
